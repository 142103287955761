@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Jost', monospace;
}
