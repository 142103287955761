@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap');

.container {
    width: 100%;
    height: 100vh;
    background-color: #fcfeff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container img {
    width: 140px;
    height: 100px;
}

.spinner_container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout {
    width: 100%;
    height: auto;
}

.mainbody_left {
    width: calc(100vw - 300px);
    left: 300px;
    top: 84px;
    min-height: calc(100vh - 85px);
    position: relative;
    transition: left 300ms ease;
}

.mainbody {
    width: calc(100vw - 130px);
    left: 140px;
    top: 84px;
    min-height: auto;
    position: relative; 
    transition: left 300ms ease;
}

@media screen and (max-width: 991px){
    .mainbody {
        /* top: 162px; */
        left: 130px;
    }
}