@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap');

/* @tailwind base; */
@tailwind components;
@tailwind utilities; 

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

body {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 6px !important;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #292929 !important;
  border-radius: 4px;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.btn-secondary, .btn-secondary.focus, .btn-secondary:focus  {
  background-color: grey !important;
  border-color: grey !important;
  color: #fff !important;
}

.btn-secondary.focus, .btn-secondary:focus  {
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%) !important;
}

.table {
  overflow-x: hidden;
}

.table::-webkit-scrollbar {
  height: 4px !important;
}

/* Track */
.table::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
.table::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 208) !important;
}

.container, .container-lg, .container-md, .container-sm, .container-xl {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  max-width: 94% !important;
}